
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'AboutPC',
  components: {
    PageWithHeader,
    Pic,
  },
})
