<template>
  <PageWithHeader>
    <template #title>
      <t path="pc_10">Web platform</t>
    </template>
    <template #body>
      <div class="content-main px-32 pt-16 pb-32">
        <p class="f-bold mb-16" style="font-size: 27px;">
          <t class="c-primary" path="pc_2">For your Desktop</t>
        </p>
        <t style="line-height: 24px;" path="pc_3">
          The online web platform allows convenient access to WeTrade directly directly from your browser with a highly customizable interface and advanced trading widgets.
        </t>
        <Pic src="user/pc" style="width: 100%;margin-top: 24px;" />
        <p style="line-height: 20px;" class="mt-8">
          <t path="pc_4">Visit</t>
          <t path="pc_pc" use-brand class="mx-4 c-primary" style="text-decoration:underline;">webtrade.wetrade.one</t>
          <t path="pc_6">through a computer browser (Google Chrome is recommended).</t>
        </p>
        <div class="items br-lg" style="margin-top: 24px;">
          <div class="item df-middle" style="margin-bottom: 24px;">
            <Pic src="user/confirm" width="24" height="24" class="mr-16" />
            <t path="pc_7">Technical indicators and charting tools</t>
          </div>
          <div class="item df-middle" style="margin-bottom: 24px;">
            <Pic src="user/confirm" width="24" height="24" class="mr-16" />
            <t path="pc_8">Technical indicators and charting tools</t>
          </div>
          <div class="item df-middle">
            <Pic src="user/confirm" width="24" height="24" class="mr-16" />
            <t path="pc_9">Technical indicators and charting tools</t>
          </div>
        </div>
      </div>
    </template>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'AboutPC',
  components: {
    PageWithHeader,
    Pic,
  },
})
</script>
<style scoped lang="scss">
.items{
  padding: 20px 24px;
  background: var(--color-background);
}
</style>
